@import '../../styles/variables';

.ThemeCard {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
  width: 12.5em;
  height: 15em;
  padding: 0.5em 1em;
  flex-shrink: 0;

  &__progress {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    margin-right: 1em;

    span {
      display: none;
    }

    &Bar {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      z-index: 2;
    }
  }

  &__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: white;
    height: 77%;
    width: 77%;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &Icon {
      font-size: 6 em;
    }
  }

  &__name {
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 1.25em;
  }

  &__expand {
    font-size: 2em;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    color: #d5d5d5;
  }
}
