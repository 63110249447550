@import '../../../styles/variables';

.ThemePreview {
  position: fixed;
  background: rgba($color: #ffffff, $alpha: 0.75);
  height: 100vh;
  width: 100vw;
  z-index: 2;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__body {
    max-width: min(75%, 50em);
    max-height: 75%;
    background: white;
    padding: 2em;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 100px 25px 25px 25px;
    overflow: auto;

    &Content {
      display: flex;

      &Image {
        height: 10em;
        width: 10em;
        margin-right: 2.5em;
        flex-shrink: 0;

        img {
          width: 100%;
        }
      }

      &Info {
        &Title {
          font-size: 2.5em;
        }

        &Desc {
          color: #686868;
        }
      }
    }

    &Buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 2em;

      &Entry {
        margin-right: 1em;

        &:last-child {
          margin-right: 0;
        }
      }

      a {
        text-decoration: none;
      }

      .Button {
        background-color: $secondary-color;
        color: white;
      }

      &Entry {
        &--accent {
          &.Button {
            background-color: $accent-color;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    &__body {
      &Content {
        flex-direction: column;
        align-items: center;

        &Image {
          margin: 0;
          margin-bottom: 2em;
        }
      }

      &Buttons {
        flex-direction: column;

        .Button {
          margin: 0;
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
