.Layout {
  $menu-height: 5rem;

  height: 100%;
  display: flex;

  .Layout__menu {
    height: $menu-height;
    width: 100%;
  }

  &__content {
    // margin-top: $menu-height;
    // height: calc(100% - #{$menu-height});
    width: 100%;

    & > div {
      padding-top: $menu-height;
      min-height: 100vh;
    }

    &--full {
      margin-left: 0;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    .Layout__menuMobile {
      width: 100%;
    }

    &__content {
      margin-left: 0;
      // margin-top: $menu-height;
      width: 100%;
      // height: calc(100% - #{$menu-height});

      & > div {
        padding-top: $menu-height;
        min-height: 100vh;
      }

      &--full {
        margin-top: 0;
        height: 100%;
      }
    }
  }
}
