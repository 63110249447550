@import '../../../styles/variables';

.SessionFinishedModal {
  overflow-y: scroll;

  .Modal__body {
    padding-top: 130px;
  }

  &__counter {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-35%);
    background-color: #ffffff;
    border-radius: 50%;
    width: 200px;
    height: 200px;

    &Value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 65%;
      height: 65%;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: white;
      border-radius: 50%;
      font-weight: bold;

      &Numbers {
        font-size: 1.25em;

        span {
          font-size: 2em;
          color: $secondary-color;
        }
      }

      &Label {
        text-transform: uppercase;
        font-size: 0.75em;
        text-align: center;
      }
    }
  }

  &__body {
    padding: 1em 3em 3em 3em;
    display: flex;
    flex-direction: column;
    align-items: center;

    &Title {
      font-size: 1.5em;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0.25em;
    }

    &Text {
      font-size: 1.2em;
      margin-bottom: 2em;
    }

    &Points {
      margin-bottom: 2em;
      border-radius: 10px;
      border: 4px solid $success-color;
      color: $success-color;
      padding: 0.25em 1em;
      text-transform: uppercase;
      font-size: 1.5em;
      font-weight: bold;
      position: relative;

      &Trophy {
        position: absolute;
        top: 0;
        right: 0;
        height: 2em;
        width: 2em;
        transform: translateX(60%) translateY(-40%);
        background-color: white;

        img {
          height: 90%;
          width: 90%;
          object-fit: cover;
        }
      }

      span {
        font-size: 2em;
      }
    }

    &Buttons {
      display: flex;

      .Button {
        margin-right: 2em;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    &__body {
      &Buttons {
        flex-direction: column;

        .Button {
          margin-right: 0;
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}