@import '../../styles/variables';

.Button {
  box-shadow: $shadow;
  text-transform: uppercase;
  border-radius: 10em;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &--disabled {
    background-color: $light-gray;
    color: white;
    cursor: not-allowed;

    .Button__progress {
      background: unset;
    }
  }

  &--dangerColor {
    color: $error-color;
    border: 4px solid $error-color;
  }

  &--secondaryColor {
    color: white;
    background-color: $secondary-color;
  }

  &--accentColor {
    color: white;
    background-color: $accent-color;
  }

  &__content {
    display: flex;
    align-items: center;
    z-index: 2;
    padding: 1em 2em;

    img {
      height: 2em;
      margin-right: 0.5em;
    }
  }

  &__progress {
    position: absolute;
    left: 0;
    z-index: 0;
    height: 100%;
    background: repeating-linear-gradient(135deg,
        #f8a930,
        #f8a930 10px,
        #fabe37 10px,
        #fabe37 20px);
  }
}