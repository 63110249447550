@import '../../styles/variables';


.Background {
  position: absolute;
  width: 100%;
  left: 0;
  top: -100px;
  scale: 2;
}

.Home {
  padding: 3em;
  background-color: #dedede;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;

  &__background {
    width: 100%;
    object-fit: cover;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-5px);
  }

  &__selectCampaignsModal {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 3;
    padding: 3em;

    &Body {
      background-color: white;
      border-radius: 5px;
      overflow: hidden;
      margin-top: 4em;

    }

    &Entry {
      text-transform: uppercase;
      padding: 1em 2em;
      cursor: pointer;
      font-size: 1.5em;

      &--selected {
        color: #f9ac30;
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 2em;

    &Content {
      position: relative;
      z-index: 1;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__placeholder {
    position: relative;
    align-self: center;
    width: 75%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $light-gray-background;
    text-shadow: 1px 1px 5px black;
    font-size: 1.5em;
    text-align: center;
  }

  &__loader {
    width: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  &__title {
    color: white;
    text-transform: uppercase;
    font-size: 2em;
    margin-bottom: 1em;
    position: relative;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    align-self: flex-start;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5em;
    padding: 0 0.25em 0 0.5em;

    i {
      height: 100%;
      font-size: 1.5em;
      margin-left: 0.25em;
      text-transform: none;
    }
  }

  &__sessions {
    display: flex;
    margin-bottom: 2em;

    // &Random {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   width: 100%;
    //   height: 100%;
    //   background-color: red;

    //   &Button {
    //     box-shadow: 0px 2.5px 5px 0 rgba(0, 0, 0, 0.1);
    //     height: 15em;
    //     padding: 2em;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     border-radius: 3em;
    //   }
    // }

    &Certification {
      box-shadow: 0px 2.5px 5px 0 rgba(0, 0, 0, 0.1);
      height: 15em;
      padding: 2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 6px;
    }

    &Random {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      margin-right: 2.5em;
      align-items: center;
      color: white;

      &Body {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: #f9ac30;
        border-radius: 6px;
        box-shadow: 0px 5px 5px rgb(0 0 0 / 10%);
        padding: 2em;
        opacity: 0.95;



        i {
          text-transform: none;
        }

        &Image {
          position: absolute;
          top: 0;
          transform: translateY(-25%);

          svg {
            height: 6.5em;

          }
        }

        &Title {
          display: flex;
          align-items: center;
          font-size: 1.25em;
          text-align: center;
          margin-bottom: 1em;

          i {
            margin-right: 0.25em;
          }
        }

        &Footer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: f9ac30;

          &Button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            background-color: white;
            box-shadow: 0px 2.5px 5px 0 rgba(0, 0, 0, 0.2);
            border-radius: 10em;
            padding: 1em 2em;
            z-index: 2;

            &Title {
              font-weight: normal;
              text-transform: uppercase;
              color: black;
            }

            &:hover {
              box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
              cursor: pointer;
            }
          }
        }
      }

      &Titlemobile {
        display: flex;
        align-items: center;
        font-size: 1.25em;
        text-transform: uppercase;
        text-align: center;

        i {
          margin-right: 0.25em;
        }
      }
    }

    &Certification {
      flex-grow: 3;
      background-color: white;
      justify-content: flex-end;
      opacity: 0.95;


      &Image {
        position: absolute;
        top: 0;
        transform: translateY(-25%);

        img {
          height: 7.5em;
          width: auto;
        }
      }

      &Description {
        font-size: 1.25em;
        margin-bottom: 1em;
      }

      &Buttons {
        display: flex;

        .Button {
          margin-right: 1em;
          max-width: 25em;
          font-weight: normal;

          &:last-child {
            margin-right: 0;
          }
        }

        &Certification {
          color: white;

          &Validated {
            &.Button {
              background-color: #e5efda;
              color: #9ed36a;
            }
          }
        }
      }
    }
  }

  &__themes {
    box-shadow: 0px 2.5px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 6px;
    padding: 2em;
    display: flex;
    flex-direction: column;
    opacity: 0.95;

    &Title {
      font-size: 1.5em;
      margin-bottom: 1em;
    }

    &Content {
      display: flex;
      flex-wrap: wrap;

      .ThemeCard {
        width: 100%;
        height: 6em;
        cursor: pointer;

        &__wrapper {
          padding: 0.5em;
          width: calc(100% / 3);
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 1.5em;
    position: relative;

    &__selectCampaignsModal {
      padding: 1.5em;

      &Body {
        margin-top: 3.5em;
      }

      &Entry {
        padding: 0.75em 1.25em;
      }
    }

    &__title {
      text-align: center;
    }

    &__sessions {
      flex-direction: column;
      position: -webkit-sticky;
      position: sticky;
      top: -1px;
      z-index: 3;
      // padding-top: 5em;
      // margin-top: -5em;

      &.isSticky {
        padding-top: calc(5em + 1px);
        transition: 0.5s;
        // transform: translateX(-1.5em);
        // width: calc(100% + 3em);

        // .Home__sessionsRandom {
        // width: 100%;
        // border-radius: 0;
        // box-shadow: 0px 1.5px 3.5px 0 rgba(0, 0, 0, 0.35);

        // }
      }

      &.isNotSticky {
        transition: 0.5s;
      }

      &Random {
        width: 100%;
        height: auto;
        border-radius: 50px;
        padding: 1em;
        box-shadow: 0px 1.5px 3.5px 0 rgba(0, 0, 0, 0.35);
        margin-right: 0;
        background-color: #f9ac30;

        &Button {
          background-color: #f9ac30;
          box-shadow: 0px 0px 0px rgb(0 0 0 / 10%);


          &Title {
            font-size: 1em;
          }
        }
      }

      &Certification {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: row;
        z-index: 3;
        padding: 1em;
        border-radius: 0;
        background: linear-gradient(to top,
            #f5f5f5,
            rgba(245, 245, 245, 0.75),
            rgba(245, 245, 245, 0.6),
            transparent);

        .Button {
          margin-right: 1em;
          font-weight: normal;

          &__content {
            padding: 0.75em 1em;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &__themes {
      background-color: unset;
      box-shadow: none;
      padding: 0;
      padding-bottom: 5em;

      &Title {
        color: white;
        justify-content: center;
        font-size: 1.25em;
      }

      .ThemeCard {
        &__wrapper {
          width: 100%;
          padding: 0;
          margin-bottom: 0.5em;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &__name {
          max-height: 100%;
        }
      }
    }
  }
}