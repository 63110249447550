@import '../../styles/variables';

.Layout__content>.Session {
  padding-top: 0;
}

.Background {
  position: absolute;
  width: 100%;
  left: 0
}

.Session {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  video {
    max-width: 50em;
    align-self: center;
  }

  &__background {
    width: 100%;
    object-fit: cover;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-5px);
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.5em 3em;
    max-width: 100em;
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5em;

    &Back {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      cursor: pointer;
      color: #f8a930;
      background: white;
      padding: 0.25em;
      border-radius: 50%;
      border: 3px solid #f8a930;
    }

    &Quit {
      color: $error-color;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      cursor: pointer;
      background: white;
      padding: 0.25em;
      border-radius: 50%;
      border: 3px solid $error-color;
    }

    &Progress {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 1em;
      flex-grow: 1;
      margin: 0 5em;
      font-weight: bold;
      text-transform: uppercase;
      color: white;

      .ProgressBar {
        width: 100%;
        background-color: white;
      }
    }
  }

  &__correction {
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }

  @media (max-width: 1000px) {
    background: #dedede;

    &__header {
      &Progress {
        margin: 0 2em;
      }
    }

    &__body {
      flex-grow: 1;
      padding: 1.5em;
      padding-bottom: 6em;
    }

    &__content {
      flex-grow: 1;
    }

    &__buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0;
      width: 100%;
      height: 3em;
      font-size: 1.5em;

      .Button {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }
  }
}