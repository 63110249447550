@import '../../../styles/variables';

.Content {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
  box-shadow: $shadow;
  position: relative;

  &--loading {
    justify-content: center;
    padding-bottom: 0;
  }

  &__report {
    position: absolute;
    top: 1em;
    right: 1em;
    padding: 0.25em 0.5em;
    background-color: #eab875;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
  }

  &__content {
    display: flex;

    &--mediaXL {
      flex-direction: column-reverse;

      .Content__image {
        align-self: center;
        padding: 0;
        margin-bottom: 1em;
        width: auto;
        max-width: 75%;
      }
    }
  }

  &__image {
    width: 75%;
    max-height: 35em;
    padding-right: 3em;

    img,
    .video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  // &__image {
  //   max-height: 25em;

  //   img,
  //   video {
  //     height: 100%;
  //     width: 100%;
  //     object-fit: cover;
  //     max-width: none;
  //   }
  // }

  &__title {
    display: flex;
    flex-direction: column;
    padding: 0 3em;
    margin-bottom: 1em;
    margin-top: 2em;

    &Points {
      background-color: $secondary-color;
      color: white;
      padding: 0.125em 0.5em;
      border-radius: 3px;
      margin-bottom: 0.5em;
      align-self: flex-start;

      &--succeeded {
        background-color: $success-color;
      }
    }

    &Text {
      font-size: 1.5em;
    }
  }

  &__answers {
    padding: 0 3em;
    display: flex;
    flex-direction: column;

    .Answer {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }

      cursor: pointer;

      &--reorderable {
        cursor: grab;
      }

      &--disabled {
        cursor: default;
      }
    }
  }

  &__timeLeft {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    font-size: 2em;
  }

  @media (max-width: 1000px) {
    &__image {
      width: 100%;
      max-height: 25em;
      padding: 0;

      img,
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        max-width: none;
      }
    }

    &__content {
      &Answers {
        width: 100%;
      }

      &--mediaXL {
        flex-direction: column;
      }
    }
  }
}
