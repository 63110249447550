$primary-color: #092530;
$secondary-color: #62a5e2;
$accent-color: #ed931b;
$dark-gray: #7f7f7f;
$light-gray: #e3e3e1;
$light-gray-background: #efefef;

$error-color: #d53a3a;
$success-color: #23d0ad;

$header-height: 5em;
$shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    background-color: transparent;

    &:vertical {
      width: 0.5em;
    }

    &:horizontal {
      height: 0.5em;
    }

    &-corner {
      background-color: transparent;
    }

    &-thumb {
      border-radius: 1em;
      background-color: $light-gray;
    }
  }
}
