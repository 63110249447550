@import '../../../styles/variables';

.Correction {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: $shadow;

  &__header {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    background-color: $success-color;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    padding: 1em;

    &--invalid {
      background-color: $error-color;
    }
  }

  &__body {
    padding: 2em 3em;
    display: flex;
    flex-direction: column;

    &--sortable {

      .Correction__bodyAnsweredAnswers,
      .Correction__bodyRightAnswers {
        display: block;

        .Correction__bodyAnswer {
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &Answered {
      margin-bottom: 2em;

      &Label {
        font-size: 1.5em;
        margin-bottom: 1em;
      }

      &Answers {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1em;
      }
    }

    &Question {
      font-size: 1.5em;
      margin-bottom: 0.75em;
    }

    &Answer {
      border-radius: 10px;
      background-color: $light-gray-background;
      display: flex;
      align-items: center;
      padding: 1em;

      &--valid {
        background-color: $success-color;
        color: white;
      }

      &--invalid {
        background-color: $error-color;
        color: white;
      }

      &Icon {
        margin-right: 1em;
        height: 1.25em;
      }
    }

    &RightAnswers {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1em;
    }

    &Separator {
      background-color: $light-gray-background;
      width: 100%;
      height: 1px;
      margin-top: 2em;
      margin-bottom: 2em;
    }

    &Text {
      color: $dark-gray;
      font-size: 1.5em;
    }

    &Points {
      display: flex;
      align-items: center;
      margin-top: 2em;
      font-weight: bold;

      &Label {
        font-size: 0.75em;
        margin-right: 1em;
      }

      &Value {
        color: $success-color;
        text-transform: uppercase;

        span {
          font-size: 1.5em;
        }
      }
    }

    &Image {
      align-self: center;
      margin-bottom: 2em;
      max-width: 50%;
      max-height: 20em;
    }

    &GoodPractices {
      margin-top: 1em;
      font-size: 1.5em;
    }
  }

  @media (max-width: 1000px) {
    &__body {
      &Answered {
        &Answers {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &RightAnswers {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}