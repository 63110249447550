.Themes {
  padding: 5em 3em;
  background-image: url('../../../assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  &__background {
    &Overlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
      background-color: white;
      opacity: 0.5;
      height: 100%;
      width: 100%;
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  }

  &__theme {
    width: 20em;
    height: 30em;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    flex-shrink: 0;

    &:last-child {
      margin-right: 0;
    }

    &Progress {
      margin-bottom: 2em;
    }

    &Image {
      margin-bottom: 1em;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      justify-content: center;
      overflow: hidden;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &Name {
      text-align: center;
      font-weight: bold;
    }
  }
}
