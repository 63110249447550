.screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #e3e3e1;
}

.tutorial {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
}

.tutorialHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6.7%;
}

.tutorialHeader__title {
    text-transform: uppercase;
    font-weight: bold;
}

.tutorialBody {
    display: flex;
    width: 100%;
    height: 88%;
    background-color: white;
    border-radius: 1em;
}


.tutorialBodyTab {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 18em;
    background-color: gray;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
}

.tutorialBodyTab__notSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6em;
    background-color: gray;
    color: white;

    &:hover {
        color: #62a5e2;
        cursor: pointer;
    }
}

.tutorialBodyTab__Selected {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6em;
    background-color: white;
    color: #62a5e2;

    &:hover {
        color: #62a5e2;
        cursor: pointer;
    }

}

.tutorialBodyTab__title {
    text-transform: uppercase;
    font-weight: bold;
    padding: 1em;
}

.tutorialBodyPlayer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.tutorialBodyPlayer__video {
    width: 80%;
    height: 80%;
}