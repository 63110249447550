@import '../../styles/variables';

.ProgressBar {
    border-radius: 10px;
    background-color: $light-gray;
    height: 15px;
    overflow: hidden;
    position: relative;

    &__target {
        background-color: #8f8f8f;
        position: absolute;
        width: 1%;
        height: 100%;
    }

    &__averagepeople {
        background-color: #d24747;
        position: absolute;
        width: 1%;
        height: 100%;
    }

    &__number {
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        color: #525252;
        position: absolute;
        height: 100%;
    }

    &__fill {
        height: 100%;
        overflow: hidden;
    }

}