@import '../../../styles/variables';

.Layout__menu {
  padding: 1rem 1.5rem;
  position: fixed;
  background-color: white;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 17.5em;
  box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  color: $dark-gray;
  z-index: 2;

  a {
    text-decoration: none;
    color: unset;

    &:visited {
      color: unset;
    }
  }

  &Mobile {
    display: none;
  }

  &Logo {
    height: 100%;
    flex-shrink: 0;

    img,
    svg {
      height: 100%;
      max-width: 20em;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
  }

  &Infos {
    &Name {
      font-weight: bold;
      margin-bottom: 0.25em;
      color: $primary-color;
    }

    &Rank {
      font-size: 0.75em;
      font-weight: bold;

      span {
        font-size: 1.25em;
        color: $secondary-color;
      }

      a {
        color: $secondary-color;
        text-decoration: none;
      }
    }
  }

  &Scoring {
    display: flex;
    padding: 0 1em;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 0 10px 10px 0;
    height: 100%;

    &Infos {
      display: flex;
      height: 100%;
      margin-right: 2em;

      a {
        text-decoration: none;
      }

      &Stat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-right: 2em;
        flex-shrink: 0;

        &:last-child {
          margin-right: 0;
        }

        span {
          margin-right: 0.5em;
          font-size: 0.75em;
        }

        &Value {
          font-size: 1.75em;
          font-weight: bold;
          margin-bottom: -0.25em;
        }

        &Label {
          color: $light-gray;
          font-style: italic;
        }
      }

      &Points {
        .Layout__menuScoringInfosStatValue {
          color: #d32d26;
        }
      }

      &Rank {
        .Layout__menuScoringInfosStatValue {
          color: #f17d28;
        }
      }

      &Completion {
        .Layout__menuScoringInfosStatValue {
          color: #f9ac30;
        }
      }
    }

    &Profile {
      height: 100%;

      &Picture {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        &Content {
          background: white;
          height: 3em;
          width: 3em;
          object-fit: cover;
          border-radius: 50%;
          z-index: 2;
          position: relative;

          &Wrapper {
            position: relative;
          }
        }

        &Completion {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }

        &Points {
          position: relative;
          display: flex;
          justify-content: center;
          width: 6.5em;
          font-size: 0.75em;
          color: white;
          font-weight: bold;
          background-color: $secondary-color;
          padding: 0.25em 0.5em;
          border-radius: 3px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    $mobile-menu-height: 7.5em;

    padding: 1rem 1rem;

    &Logo {
      margin-bottom: 0;
      max-width: 30%;
      margin-right: 1em;
      flex-shrink: 1;

      img,
      svg {
        height: 100%;
        width: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }

    &Previous {
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        margin-right: 0.25em;
      }
    }

    &Quit {
      color: $error-color;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
    }

    &Scoring {
      font-size: 0.65em;

      &Profile {
        &Picture {
          &Content {
            width: 4.5em;
            height: 4.5em;
          }
        }
      }
    }
  }
}

.didactitielButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 2.5em;
  margin-right: auto;
  background-color: rgb(252, 252, 252);
  box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
  color: rgb(92, 92, 92);
  border-radius: 5px;

  &:hover {
    box-shadow: $shadow;
    color: rgb(255, 166, 0);
    cursor: pointer;
  }
}