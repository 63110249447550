@import '../../styles/variables';

.Layout__content>.MyAccount {
  padding-top: 6em;
}

.MyAccount {
  min-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
  background-color: $light-gray;


  &__header {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
  }

  &__window {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: gray;
    border-radius: 10px;
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 5em 1em 1em 1em;
    width: 100%;
    height: 100%;
    flex-grow: 1;

    &Body {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      // background-color: black;

      &Left {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &Picture {
          width: 15em;
          height: 15em;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 1em;
          position: relative;

          &:hover {
            .MyAccount__contentLeftPictureHover {
              display: flex;
            }
          }

          &Hover {
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            display: none;
            background: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            position: absolute;
            top: 0;

            i {
              color: white;
              font-size: 5em;
            }
          }

          input[type='file'] {
            display: none;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        &Name {
          font-size: 2em;
        }
      }

      &Right {
        width: 50%;

        .InputWithLabel {
          margin-bottom: 1.5em;
          width: 100%;
        }
      }
    }

    &Footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
    }

    &Tabs {
      background-color: gray;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 15%;

      &Profile {
        background-color: gray;
        color: white;
        width: 100%;
        padding: 2em;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: #62a5e2;
          cursor: pointer;
        }
      }


      &Stats {
        background-color: gray;
        color: white;
        width: 105%;
        padding: 2em;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: #62a5e2;
          cursor: pointer;
        }
      }

      &Selected {
        background-color: white;
        color: #62a5e2;
        width: 105%;
        padding: 2em;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &Deconnection {
      margin-top: auto;
      width: 80%;


    }

  }

  &Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 100%;
    width: 100%;
  }

  &Stats {
    background-color: white;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: 1em;
    overflow-y: auto;


    &__table {
      border-radius: 10px;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      overflow: hidden;

      &Statsbutton {
        height: 2em;
        width: 3em;
        border-radius: 5px;
        background-color: white;
        border: 2px solid #E8E8E8;
        box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);

        &:hover {
          cursor: pointer;
        }

      }

      table {
        width: 100%;
      }

      th {
        text-align: left;
        padding: 1em;
        position: sticky;
        top: 0;
        background-color: #ED931B;
        // border-radius: 20px;
        color: white;
      }

      td {
        text-align: left;
        padding: 1em;

      }

      thead {
        background-color: white;
        // border-bottom: 1px solid #C7C7C7;
        border-radius: 20px;
      }

      tr:nth-child(odd) {
        background-color: white;
        overflow: hidden;
      }

      tr:nth-child(even) {
        background-color: #dedede;
      }
    }

    &Header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 10%;


      &Titlecampaign {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.9em;
        white-space: nowrap;
        overflow: hidden;
        color: #3d3d3d;
        height: 100%;

        &:hover {
          cursor: default;
        }
      }

      &Datecampaign {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.2em;
        white-space: nowrap;
        overflow: hidden;
        color: #3d3d3d;
        height: 100%;

        &:hover {
          cursor: default;
        }
      }

    }


    &Charts {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 85%;
      width: 100%;
      padding: 1%;



      &__header {
        display: flex;
        justify-content: space-between;
        height: 10%;
        width: 100%;


        &__progress {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          border-radius: 10px;
          background-color: white;
          box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
          width: 75%;
          padding: 0 1em;

          &Title {
            text-transform: uppercase;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            color: #3d3d3d;
          }

          .ProgressBar {
            background-color: rgb(234, 234, 234);
            width: 100%;
          }

          &:hover {
            box-shadow: $shadow;
          }
        }

        &Numbercase {
          height: 100%;
          width: 6%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          background-color: white;
          box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);

          &__number {
            font-size: 100%;
            text-transform: uppercase;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            color: #8f8f8f;
          }

          &:hover {
            box-shadow: $shadow;
            cursor: default;
          }
        }

        &__button {
          height: 100%;
          width: 22%;
          border-radius: 10px;
          box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);

          &Validated {
            height: 100%;
            width: 22%;
            border-radius: 10px;
            color: #9ed36a;
            background-color: #e5efda;
            box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);

            .Button {
              &__content {
                padding: 6%;
              }
            }

            &:hover {
              box-shadow: $shadow;
            }
          }

          &Notvalidated {
            height: 100%;
            width: 22%;
            border-radius: 10px;
            box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
          }

          &:hover {
            box-shadow: $shadow;
          }
        }
      }

      &__card {
        width: 100%;
        height: 80%;
        border-radius: 10px;
        // background-color: white;
        box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
        padding: 1em;

        &Switch {
          display: flex;
          justify-content: space-between;
          width: 15em;
          height: 8%;

          &Name {
            font-size: medium;
            color: #3d3d3d;
            user-select: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;

          }
        }

        &__details {
          height: 80%;
          width: 100%;
          // background-color: rgb(255, 255, 255);

          &Title {
            margin-top: 0.7em;
            height: 9%;
            text-transform: uppercase;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            color: #3d3d3d;
            user-select: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;

            &:hover {
              cursor: default;
            }
          }

          &__body {
            height: 100%;
          }
        }

        &:hover {
          box-shadow: $shadow;
        }

      }

    }

    &Footer {
      height: 5%;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      &Returnbutton {
        height: 100%;
        width: 8%;
        border-radius: 10px;
        background-color: white;
        border: 2px solid #E8E8E8;
        box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        color: #8f8f8f;

        &:hover {
          box-shadow: $shadow;
          cursor: pointer;
          color: #62a5e2;
        }
      }
    }

  }


  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .Button {
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }
  }


  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: rgb(242, 158, 52);
    border-radius: 20px;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
    }
  }

  .switch:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
  }

  input[type='checkbox']:checked+.switch:after {
    transform: translateX(20px);
  }

  input[type='checkbox']:checked+.switch {
    background-color: #7983ff;
  }

  .offscreen {
    position: absolute;
    left: -9999px;
  }


  @media (max-width: 1145px) {
    &Stats {

      &Charts {

        &__header {

          &__button {

            &Validated {

              .Button {
                &__content {
                  font-size: smaller;
                }
              }
            }
          }
        }
      }
    }

    .MyAccount {

      &__window {
        @media (max-width: 596px) {
          flex-direction: column;
        }
      }

      &Stats {
        @media (max-width: 600px) {
          padding: 1vw;

          &__table {
            width: calc(100%);

            th {
              padding: 1em;
              font-size: 3vw;
            }

            td {
              text-align: left;
              padding: 1em;
              font-size: 2.6vw;
            }

            &Statsbutton {
              &:hover {
                cursor: pointer;
              }
            }
          }

          &Header {
            &Titlecampaign {
              font-size: 6vw;
            }

            &Datecampaign {
              font-size: 4vw;
            }
          }

          &Charts {
            &__header {
              flex-direction: column;
              justify-content: space-around;
              height: 20%;

              &__progress {
                height: 40%;
                width: 100%;

                &Title {
                  font-size: 0.9em;
                }
              }

              &__button {

                &Validated {
                  height: 40%;
                  width: 50%;

                  .Button {

                    &__content {
                      font-size: 1em;

                      @media (max-width: 596px) {
                        font-size: 3.2vw;
                      }
                    }
                  }
                }

                &Notvalidated {
                  height: 40%;
                  width: 50%;

                  .Button {
                    &__content {
                      font-size: 1em;

                      @media (max-width: 596px) {
                        font-size: 3.2vw;
                      }
                    }
                  }
                }
              }
            }

            &__card {
              height: 70%;
            }
          }
        }
      }

      &__content {
        &Tabs {
          @media (max-width: 596px) {
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 11vw;
            padding: 0%;

            &Selected {
              width: 25vw;
              font-size: 3vw;
              padding: 0%;
              background-color: white;
              height: 105%;
            }

            &Profile {
              width: 25vw;
              padding: 0%;
              font-size: 3vw;
            }

            &Stats {
              width: 25vw;
              font-size: 3vw;
              padding: 0%;
              height: 105%;
            }
          }
        }

        &Deconnection {
          @media (max-width: 596px) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-top: 0;
            width: auto;
            height: 100%;
            width: 10vw;

            &Cadre {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgb(253, 253, 253);
              height: 83%;
              width: 83%;
              border-radius: 100vw;
              border: 1vw solid red;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {

    padding: 0.5em;

    .MyAccount {

      &__content {
        display: flex;
        flex-direction: column;
        padding: 2em;

        &Body {
          flex-direction: column;

          &Left {
            &Name {
              margin-bottom: 1em;
            }
          }

          &Left,
          &Right {
            width: 100%;
            height: auto;

            .InputWithLabel {
              width: auto;
            }
          }
        }

        &Tabs {}

        &Deconnection {
          .Button {
            &__content {
              font-size: small;
            }
          }
        }
      }
    }
  }
}