@import '../../styles/variables';

.InputWithLabel {
  &__label {
    color: $dark-gray;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }

  &__input {
    input,
    input[type='text'],
    input[type='password'],
    select {
      border: 1px solid $light-gray;
      border-radius: 5px;
      background-color: #fafafa;
      outline: none;
      padding: 0.5em;
      font-size: 1.1em;
      width: 100%;
    }
  }
}
