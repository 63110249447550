@import '../../../styles/variables';

.Answer {
  background-color: $light-gray-background;
  padding: 1em;
  border-radius: 10px;
  font-size: 1.2em;
  display: flex;
  align-items: center;

  &__checkbox {
    width: 2em;
    height: 2em;
    margin-right: 1em;
    background-color: white;
    border: 3px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &--singleSelection {
      border-radius: 50%;

      .Answer__checkboxContent {
        border-radius: 50%;
      }
    }

    &--selected {
      border: 3px solid $secondary-color;
    }

    &Content {
      width: 1.2em;
      height: 1.2em;
      background-color: $secondary-color;
    }
  }

  &--disabled {
    color: $dark-gray;

    .Answer__checkbox {
      &--selected {
        border: 3px solid $dark-gray;
      }

      &Content {
        background-color: $dark-gray;
      }
    }
  }

  &--reorderable {
    cursor: grab;

    i {
      font-size: 1.5em;
      margin-right: 0.5em;
    }
  }
}
