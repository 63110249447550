@import './styles/variables';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  color: $primary-color;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Futura PT', 'Lato', Arial, Helvetica, sans-serif;
}

i {
  font-family: 'Material Icons';
  text-decoration: none;
  font-style: normal;
  user-select: none;
}

#app {
  height: 100%;
}

@font-face {
  font-family: 'Futura';
  src: url('../assets/fonts/Futura-Medium.woff2') format('woff2'),
    url('../assets/fonts/Futura-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../assets/fonts/FuturaPT-DemiBold.woff2') format('woff2'),
    url('../assets/fonts/FuturaPT-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../assets/fonts/FuturaPT-Bold.woff2') format('woff2'),
    url('../assets/fonts/FuturaPT-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html body {
  .mobileVisible {
    display: none;
  }
}

@media (max-width: 1000px) {
  html {
    // font-size: 0.75em;

    body {
      .mobileVisible {
        display: flex;
      }

      .mobileHidden {
        display: none;
      }
    }
  }
}
