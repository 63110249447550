@import '../../../styles/variables';

.LoginInput {
  border: 1px solid $light-gray;
  height: 4em;
  display: flex;
  align-items: center;
  padding: 0.5em 1em;

  &__icon {
    height: 100%;

    svg {
      height: 100%;
      width: auto;
    }
  }

  &__separator {
    margin: 0 1em;
    background-color: $light-gray;
    width: 1px;
    height: 100%;
  }

  &__input {
    height: 100%;
    flex-grow: 1;
    display: flex;

    &Content {
      height: 100%;
      flex-grow: 1;

      input,
      input[type='text'],
      input[type='password'] {
        height: 100%;
        border: none;
        outline: none;
        font-family: 'Futura';
        font-size: 1.2em;
        width: 100%;

        &::placeholder {
          color: $light-gray;
        }
      }
    }

    &After {
      height: 100%;
      cursor: pointer;

      svg {
        height: 100%;
        width: auto;
      }
    }
  }
}
